import { useRouter } from 'next/router'
import Link from 'next/link'
import { Button, ButtonProps, Flex, FlexProps, Text } from '@traefiklabs/faency'
import styled from 'styled-components'
import Icon from 'react-eva-icons'
import routes from '../../routes'
import useAuthorizedRoute from '../../hooks/use-authorized-routes'
import { useWindowSize } from '../../hooks/use-window-size'
import useAuth from '../../hooks/use-auth'
import breakpoints from '../../utils/breakpoints'
import Logo from '../Logo'
import { useState } from 'react'
import AlertIcon from '../AlertIcon'
import qs from 'query-string'
import OrganizationPicker from '../../components/OrganizationPicker'

const NavItem = styled<React.FC<ButtonProps>>(Button)`
  justify-content: inherit;
  color: #06112a;
  border-radius: 4px;
  margin-bottom: 12px;
  transition-property: all;
  transition-duration: 0.15s;
  width: 100%;
  padding: 10px;

  &:active {
    color: #06112a;
  }

  &.active,
  &:hover {
    background-color: #e6ebf8;
  }

  svg {
    color: #96a2b3;
  }

  &.active svg {
    color: #06112a;
  }

  @media (max-width: ${breakpoints.laptop}) {
    padding: inherit;
    align-items: center;
    justify-content: center;
  }
`

const BottomNavItem = styled<React.FC<ButtonProps>>(Button)`
  position: relative;
  justify-content: inherit;
  color: #7a7f89;
  font-weight: 600;
  border-radius: 4px;
  transition-property: all;
  transition-duration: 0.15s;
  width: 100%;
  padding: 10px;
  text-decoration: inherit;

  &:active {
    color: #a8a8a8;
  }

  svg {
    color: #96a2b3;
  }

  &.active svg {
    color: #06112a;
  }

  @media (max-width: ${breakpoints.laptop}) {
    padding: inherit;
    align-items: center;
    justify-content: center;

    & > div {
      justify-content: center;
    }
  }
`

const ResponsiveWrapper = styled<React.FC<FlexProps>>(Flex)`
  flex-direction: column;
  justify-content: inherit;
  width: 15rem;

  @media (max-width: ${breakpoints.laptop}) {
    width: 88px;

    .NavItem--label {
      display: none;
    }
  }
`

const FeedbackMenu = styled(Flex)`
  flex-flow: column;
  background-color: #fff;
  padding: 16px;
  position: absolute;
  z-index: 10;
  right: -120px;
  top: -115px;
  box-shadow: 0 6px 12px 0 hsla(293, 84.2%, 7.5%, 0.08);
  border: 1px solid hsl(0, 0%, 86.3%);
  border-radius: 4px;

  @media (max-width: ${breakpoints.laptop}) {
    right: -180px;
  }
`

const FeedbackButton = styled.a`
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  background-color: hsl(225, 100%, 98%);
  width: 72px;
  height: 72px;
  border-radius: 8px;
  color: hsl(220, 6%, 50.8%);
  transition: background-color 200ms ease-in-out;

  & + & {
    margin-left: 18px;
  }

  &:hover,
  &:focus {
    background-color: hsl(225, 100%, 95.4%);
  }

  &:active {
    background-color: hsl(225, 100%, 91.4%);
  }
`

const OrganizationPickerWrapper = styled<React.FC<FlexProps>>(Flex)`
  flex: 1;
  transition: width 0.3s ease-in-out;

  @media (max-width: ${breakpoints.laptop}) {
    position: absolute;
    margin-left: -14px;
    margin-right: -8px;
    width: 68px;

    .advancedSelect-option-text {
      display: none;
    }

    .advancedSelect-option-avatar {
      margin-right: 0;
    }

    div[class$='ValueContainer'] {
      padding: 2px 6px;
    }

    div[class$='menu'] {
      z-index: 2;
    }

    div[class$='indicatorContainer'] {
      padding: 2px;
    }

    &:hover {
      z-index: 2;
      width: 200px;

      .advancedSelect-option-text {
        display: inherit;
      }

      .advancedSelect-option-avatar {
        margin-right: 8px;
      }
    }
  }
`

type SideBarProps = {
  onOrgSwitch?: () => void
}

const SideBar: React.FC<SideBarProps> = ({ onOrgSwitch = () => null }) => {
  const router = useRouter()
  const windowSize = useWindowSize()
  const { authorizedRoutes } = useAuthorizedRoute(routes)
  const [openFeedbackMenu, setOpenFeedbackMenu] = useState<boolean>(false)
  const { user } = useAuth()

  const hasSmallLogo = windowSize.width < +breakpoints.laptop.replace('px', '')
  const communityIssueLink = qs.stringifyUrl({
    url: 'https://community.traefik.io/new-topic',
    query: {
      title: 'Issue report',
      tags: 'issue',
      category: 'traefik-pilot',
      body: `**Report a problem**\n\n**Steps to reproduce**\n1. On the page ...\n2. When I click on ...\n\n**Expected behavior**\n\n**Current behavior**\n${
        typeof navigator !== 'undefined' && `\n----\n\`Version: ${navigator.vendor} ${navigator.appVersion}\``
      }`.trim(),
    },
  })
  const communityIdeaLink = qs.stringifyUrl({
    url: 'https://community.traefik.io/new-topic',
    query: {
      title: 'Feature request',
      tags: 'idea',
      category: 'traefik-pilot',
      body: '**Feature Request**\n\nDescribe your idea here...',
    },
  })

  return (
    <ResponsiveWrapper p={3} sx={{ height: '100vh', bg: 'white' }}>
      <Logo height={42} color="#06102A" small={hasSmallLogo} />
      <Flex mt={5} sx={{ flexDirection: 'column', flex: 1 }}>
        {!!user && (
          <Flex sx={{ position: 'relative', height: 70 }}>
            <OrganizationPickerWrapper mb={4}>
              <OrganizationPicker onOrgSwitch={onOrgSwitch} />
            </OrganizationPickerWrapper>
          </Flex>
        )}
        {(authorizedRoutes || []).map((route) => (
          <Link key={route.pathname} href={`${route.pathname}`} data-testid={`sidebar-menu-item-${route.pathname}}`}>
            <NavItem className={router?.pathname.startsWith(route.pathname) && 'active'}>
              <Flex sx={{ alignItems: 'center' }}>
                {route?.icon && (
                  <Text sx={{ color: '#06102A' }}>
                    {typeof route?.icon === 'string' ? (
                      <Icon name={route.icon} size="large" fill="currentColor" />
                    ) : (
                      route?.icon
                    )}
                  </Text>
                )}
                <Text className="NavItem--label" sx={{ color: 'inherit' }} pl={1}>
                  {route.label}
                </Text>
              </Flex>
            </NavItem>
          </Link>
        ))}
      </Flex>
      <Flex sx={{ flexDirection: 'column' }}>
        <a
          href="https://doc.traefik.io/traefik-pilot/"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <BottomNavItem>
            <Flex sx={{ alignItems: 'center' }}>
              <Icon name="book-open-outline" size="large" fill="currentColor" />
              <Text className="NavItem--label" sx={{ color: 'inherit' }} pl={1}>
                Documentation
              </Text>
            </Flex>
          </BottomNavItem>
        </a>
        <BottomNavItem
          onMouseEnter={(): void => {
            setOpenFeedbackMenu(true)
          }}
          onMouseLeave={(): void => {
            setOpenFeedbackMenu(false)
          }}
        >
          <Flex sx={{ alignItems: 'center' }}>
            <Icon name="alert-circle-outline" size="large" fill="currentColor" />
            <Text className="NavItem--label" sx={{ color: 'inherit' }} pl={1}>
              Give Feedback
            </Text>
          </Flex>
          {openFeedbackMenu && (
            <FeedbackMenu>
              <Text sx={{ fontWeight: 'bold', mb: 1 }}>What&rsquo;s on your mind?</Text>
              <Flex>
                <FeedbackButton href={communityIssueLink} target="_blank">
                  <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
                    <Flex sx={{ fontSize: '34px' }}>
                      <AlertIcon />
                    </Flex>
                    <Flex sx={{ fontSize: '14px', mt: 1 }}>Issue</Flex>
                  </Flex>
                </FeedbackButton>
                <FeedbackButton href={communityIdeaLink} target="_blank">
                  <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
                    <Flex sx={{ fontSize: '34px' }}>💡</Flex>
                    <Flex sx={{ fontSize: '14px', mt: 1 }}>Idea</Flex>
                  </Flex>
                </FeedbackButton>
              </Flex>
            </FeedbackMenu>
          )}
        </BottomNavItem>
      </Flex>
    </ResponsiveWrapper>
  )
}

export default SideBar
