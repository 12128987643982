import styled from 'styled-components'
import { default as NextLink } from 'next/link'
import { theme } from '@traefiklabs/faency'

export const StyledLink = styled.a`
  color: ${theme.colors.blue};
  font-weight: 600;
  text-decoration: inherit;

  :hover {
    text-decoration: underline;
  }
`

type LinkProps = {
  children: React.ReactNode
  href: string
  as?: string
  target?: string
  style?: React.CSSProperties
  scroll?: boolean
}

const Link: React.FC<LinkProps> = ({ children, href, as = null, scroll = true, ...props }) => {
  if (href.startsWith('/')) {
    return (
      <NextLink as={as} href={href} scroll={scroll} passHref>
        <StyledLink {...props}>{children}</StyledLink>
      </NextLink>
    )
  }

  return (
    <StyledLink href={href} {...props}>
      {children}
    </StyledLink>
  )
}

export default Link
