import { useState } from 'react'
import fetcher from '../utils/fetcher'
import { pTimeout } from '../utils/time'

export type LazyFetchResultType = {
  data: any
  nextPage?: number
  loading?: boolean
  error?: string
  statusCode?: number
}

const TIMEOUT = parseInt(process.env.FETCH_TIMEOUT, 10) || 10000

const useLazyFetch: any = (globalUrl: string, globalOptions: any = {}) => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [statusCode, setStatusCode] = useState(null)

  const fetchData = async (...params): Promise<LazyFetchResultType> => {
    const url = params.length === 2 ? params[0] : globalUrl
    const options = (params.length === 2 ? params[1] : params[0]) || {}

    try {
      setLoading(true)
      const res = await pTimeout(TIMEOUT, fetcher(url, { ...globalOptions, ...options }))

      if (res?.ok === false) {
        setError(res.statusText)
        setLoading(false)
        setStatusCode(res.status)
        return { data: null, error: res.statusText, statusCode: res.status }
      }

      setData(res.data)
      setError(null)
      setLoading(false)
      setStatusCode(res.status)
      return { data: res.data, nextPage: res.nextPage, loading: false, error: null, statusCode: res.status }
    } catch (fetchError) {
      setData(null)
      setError(fetchError)
      setLoading(false)
      setStatusCode(500)
      return { data: null, nextPage: null, loading: false, error: fetchError, statusCode: 500 }
    }
  }

  return [fetchData, { data, loading, error, statusCode }]
}

export default useLazyFetch
