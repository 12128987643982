import React from 'react'

type MascotProps = {
  width?: number
  height?: number
}

const Mascot: React.FC<MascotProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 1018 579">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fillRule="nonzero" transform="translate(-390 -397)">
          <g transform="translate(390 397)">
            <path fill="#967100" d="M265 291.148798L421.494443 541 1018 87z"></path>
            <path
              fill="#E6AE02"
              d="M216.114 148.289L265.412 290 1018 86l-784.076 35.94c-13.238.617-22.164 13.811-17.81 26.349z"
            ></path>
            <path
              fill="#967100"
              d="M265.901 352.701L420.874 542.25a1 1 0 001.382.16L1018 86 266.343 351.125a1 1 0 00-.442 1.576z"
            ></path>
            <path
              fill="#423833"
              d="M585.59 106.605c-2.597 11.529-55.735 5.586-81.425-3.041-25.69-8.627-43.45-24.708-39.654-35.899 3.796-11.19 32.462-21.805 53.398-4.631 27.148 22.243 72.895 20.394 67.681 43.571z"
            ></path>
            <path
              fill="#F5D1A1"
              d="M593.294 158.737c18.472-4.19 52.548-4.27 55.557 4.746 2.908 8.677-37.486 22.259-49.46 22.517-11.572-5.6-6.097-27.263-6.097-27.263z"
            ></path>
            <path
              fill="#0A0F2B"
              d="M585.374 285.2s26.629 6.869 50.307 2.137c23.68-4.732 12.077-91.486-6.078-117.223-18.155-25.757-31.44-48.279-43.794-38.974-12.354 9.304-3.583 11.38-14.809 21.164"
            ></path>
            <path fill="#FFF" d="M400.082 71.444C365.424 20.544 421.162 31.783 443 78l-42.918-6.556z"></path>
            <path fill="#FFF" d="M313.676 164c-66.578-34.304 1.592-55.544 51.324-18.612L313.676 164z"></path>
            <path fill="#FFF" d="M327.094 160c-62.448-29.516-3.78-38.397 43.906-8.502L327.094 160z"></path>
            <path fill="#FFF" d="M418.877 76.894C371.362 27.812 424.405 44.621 459 88l-40.123-11.106z"></path>
            <path
              fill="#35AAC6"
              d="M442.9 382.448c63.977 43.119 163.703-9.803 182.016-80.657 25.4-98.292-35.511-165.002-58.144-189.151-22.632-24.15-57.885-70.814-103.11-63.96-51.794 7.87-149.47 60.951-152.455 111.681-2.986 50.73 26.912 77.11 54.62 100.424 86.788 73.105 12.82 78.346 77.074 121.663z"
            ></path>
            <path
              fill="#076B75"
              d="M483.374 101.043c-7.916 5.996-18.947 17.627-28.192 24.626 11.348-21.097 16.725-20.375 23.39-29.4-9.94 1.124-25.414 3.771-33.667 4.714-.635-.943-1.27-1.866-1.905-2.808 3.571-3.088 5.595-4.593 9.464-5.014 11.507-1.243 24.541-4.893 36.108-4.03 2.38.18 2.58 4.532 2.36 7.4-.832 10.728-1.03 22.02-3.074 32.608-.496 2.547-.635 5.454-1.964 7.861-1.786-2.126-2.64-5.776-2.937-8.122-.932-7.22.754-19.072.417-27.835z"
            ></path>
            <path
              fill="#076B75"
              d="M513.569 68.152c6.518-3 16.075-9.45 23.692-12.956-11.156 12.917-14.875 11.67-20.953 16.91 7.258.644 18.694 1.014 24.732 1.54.32.74.64 1.46.96 2.201-3 1.617-4.659 2.358-7.478 2.104-8.397-.779-18.234-.117-26.372-2.357-1.68-.468-1.18-3.487-.62-5.436 2.14-7.267 3.92-15.02 6.898-22.034.72-1.695 1.24-3.663 2.54-5.124.96 1.714 1.04 4.364.92 6-.38 5.163-3.3 13.073-4.32 19.152z"
            ></path>
            <ellipse
              cx="526.453"
              cy="96.119"
              fill="#0A0F2B"
              rx="17.106"
              ry="11.757"
              transform="rotate(-23.284 526.453 96.119)"
            ></ellipse>
            <path
              fill="#076B75"
              d="M534 133.576c6.95 23.02 56.795 52.757 50.442 22.76-9.4-19.77-19.974-29.497-28.218-41.336L534 133.576z"
            ></path>
            <path
              fill="#FFF"
              d="M560.322 113c4.54 5.955 10.99 11.689 14.604 18.875.575 4.018-2.341 6.541-4.17 9.63-4.17 2.1-9.099 1.978-13.104-.303-6.43-6.581-10.414-14.535-15.652-21.984L560.322 113z"
            ></path>
            <path
              fill="#FFF"
              d="M528 134.72c3.502 14.69 27.296 31.798 28 5.868-6.124-6.462-9.92-14.273-14.91-21.588L528 134.72z"
            ></path>
            <path
              fill="#F5D1A1"
              d="M531.133 107.206c-6.881 1.844-13.902.595-18.046 2.618-19.158 9.283-4.502 36.933 11.601 32.748 18.9-4.9 11.978-12.238 23.719-18.923 4.442-2.519 14.834-.159 17.233-10.314 2.578-10.87-9.32-21.244-15.984-20.272-8.29 1.19-11.641 12.298-18.523 14.143z"
            ></path>
            <path
              fill="#FFF"
              d="M491 397.974c-8.057 4.228-14.411 17.507-20.164 23.71-5.311 6.166-9.68 1.997-10.643 1.186-1.202-.098-.801.771-2.986-.237-9.56-7.231 4.83-22.723 9.741-29.698 8.559-4.96 17.057.672 24.052 5.039z"
            ></path>
            <path
              fill="#FFF"
              d="M425.945 351.568c1.321 3.643-21.303 8.482-34.457 23.567-10.47 12.01-27.309 2.528-28.83-1.645-1.922-5.31-.841-15.634 20.141-22.922 21.002-7.268 42.205-1.606 43.146 1z"
            ></path>
            <path
              fill="#0A0F2B"
              d="M536.983 178.939c-13.292-13.58-24.088 23.309-60.28 41.157-25 12.31-128.703 25.711-128.703 25.711s50.831 109.336 68.363 111.023c18.363 1.767 83.319-10.542 118.56-23.626 33.082-12.29 35.836-10.443 34.944-58.033-.91-47.63-19.591-82.672-32.884-96.232zm-82.23 97.344c-14.203 1.171-26.187-3.713-26.782-10.92-.594-7.207 10.44-13.997 24.624-15.168 14.183-1.172 26.188 3.712 26.782 10.92.594 7.187-10.42 13.996-24.623 15.168z"
            ></path>
            <path
              fill="#FFF"
              d="M572.738 289C560.34 299.536 411.766 338.326 396 334.575c0 0 1.774 4.19 7.993 11.533 6.219 7.343 166.472-32.824 168.067-41.145 1.595-8.2.678-15.963.678-15.963z"
            ></path>
            <path
              fill="#FFF"
              d="M572.213 266C558.928 276.79 401.64 318.984 385 315.394c0 0 1.589 6.498 8.082 13.818 6.493 7.32 178.515-38.525 179.607-47.209.914-7.26-.476-16.003-.476-16.003z"
            ></path>
            <path
              fill="#84634D"
              d="M439.456 7.644c-37.017-4.587-66.256.08-96.57 18.348-23.869 14.356-44.913 33.1-56.828 58.636-10.105 21.703-12.81 46.226-7.14 69.359.675 2.74 1.471 5.46 2.366 8.14 2.407 7.11 5.55 13.94 9.05 20.592 3.163 6.076 6.564 12.013 10.025 17.93h.04c19.076 21.982 14.322 19.857 29.797 30.083 6.484 8.479 11.238 14.118 21.86 21.048 3.48 2.264 58.578-13.443 80.16-24.265 8.851-4.428 23.372 10.187 31.726 12.828 5.868 1.866 13.724-1.51 14.878-7.546 1.81-9.69-8.712-15.051-13.824-23.45-6.862-11.319-40.617-47-44.774-59.57-6.445-19.499-7.042-35.424 6.763-49.601C454.673 71.74 479.795 59.807 508 56.948c-.04-.02-24.406-43.823-68.544-49.304z"
            ></path>
            <path
              fill="#423833"
              d="M451.741 199.262l-14.258-17.209-2.492-3.041c-11.47-14.07-17.596-22.698-19.53-28.547-7.81-23.646-6.268-40.22 7.94-54.695 25.227-25.718 55.087-41.8 84.12-44.744a4.974 4.974 0 015.453 4.442 4.971 4.971 0 01-4.448 5.447c-26.538 2.691-54.32 17.654-78.018 41.813-11.167 11.377-12.356 24.156-5.596 44.622 1.474 4.458 8.026 13.507 19.733 27.752l15.17 18.318 1.347 1.66c3.815 4.726 6.341 8.084 7.886 10.568l.196.319c.972 1.598 2.151 3.068 4.202 5.305l2.655 2.848c.14.151.274.296.405.44 6.02 6.563 8.494 11.498 7.206 18.396-1.685 8.824-12.148 13.984-20.845 11.492l-.433-.13c-2.554-.808-3.702-1.34-8.33-4.253l-5.76-3.667a100.03 100.03 0 00-3.32-2.023l-1.057-.598c-4.81-2.653-7.931-3.406-9.544-2.599-18.098 9.08-74.33 24.822-82.447 24.822a4.973 4.973 0 01-4.976-4.97 4.973 4.973 0 014.684-4.961l.292-.009c.39 0 4.108-.75 8.996-1.894l1.028-.243c5.655-1.344 12.363-3.069 19.199-4.948l2.935-.814c19.823-5.55 36.569-11.223 45.826-15.867 5.612-2.809 11.615-1.31 19.45 3.136l.607.347c.969.562 1.94 1.15 3.053 1.847l5.99 3.808c3.835 2.416 4.863 2.93 6.387 3.412 3.456 1.1 7.978-1.074 8.486-3.73.549-2.942-.672-5.377-4.766-9.84l-2.127-2.282c-2.862-3.062-4.542-5.064-6.03-7.424l-.276-.447c-1.184-1.954-3.435-4.97-6.901-9.283l-2.092-2.576z"
            ></path>
            <path
              fill="#000"
              d="M342.985 26c-23.904 14.434-44.98 33.28-56.912 58.953-10.12 21.82-12.829 46.476-7.152 69.734.678 2.755 1.475 5.49 2.371 8.185 1.195 3.574 2.59 7.067 4.123 10.521a177.391 177.391 0 004.94 10.182c3.168 6.109 6.574 12.078 10.04 18.027h.04c2.849 9.583 9.622 19.086 16.136 24.316 4.382 3.534 9.76 6.369 15.378 8.125 4.82 6.23 9.044 10.98 16.116 16.131a4.943 4.943 0 00-.897 2.835 4.987 4.987 0 004.98 4.991c5.697 0 60.896-14.055 82.51-24.935 3.286-1.637 11.274 3.514 16.553 6.928 4.104 2.655 7.968 5.15 11.474 6.268 4.582 1.458 9.92.779 14.283-1.816 3.785-2.256 6.275-5.67 7.032-9.603C384.877 189.065 305.077 109.509 342.985 26z"
              opacity="0.1"
            ></path>
            <path
              fill="#50433A"
              d="M337.558 234.416c1.583.3 3.146.459 4.709.538 6.965.359 14.72-1.335 18.817-7.033 2.75-3.845 3.383-8.926 2.611-13.608-.791-4.703-2.829-9.046-5.045-13.23-4.135-7.87-8.983-15.521-15.69-21.3-6.708-5.777-15.493-9.563-24.259-8.646-8.785.896-17.214 7.172-19.192 15.84-.97 4.223-.495 8.945.93 13.628 2.829 9.563 9.556 19.047 16.027 24.268 5.876 4.722 13.514 8.228 21.092 9.543z"
            ></path>
            <ellipse
              cx="327.209"
              cy="206.752"
              fill="#84634D"
              rx="13.634"
              ry="22.479"
              transform="rotate(-41.087 327.209 206.752)"
            ></ellipse>
            <ellipse
              cx="327.741"
              cy="206.284"
              fill="#50433A"
              rx="11.305"
              ry="18.656"
              transform="rotate(-41.087 327.741 206.284)"
            ></ellipse>
            <path
              fill="#423833"
              d="M429 45.118c.2.176.359.372.518.549 3.19 3.568 5.562 7.804 6.998 12.333 6.718-9.667 17.682-16.392 29.484-18.118-.558-.706-1.017-1.45-1.455-2.235-1.834-3.235-2.95-6.823-3.808-10.431 0-.06-.04-.157-.06-.216-12.38 1.725-23.962 8.373-31.677 18.118z"
            ></path>
            <path
              fill="#423833"
              d="M279 154.08a106.725 106.725 0 002.373 8.195c2.413 7.155 5.564 14.03 9.074 20.725 22.576-21.685 84.997-66.233 107.553-87.917-8.735-5.196-14.937-14.13-16.912-24.083C359.61 90.266 298.963 133.216 279 154.08z"
            ></path>
            <path
              fill="#99C2D1"
              d="M403.58 36.339c-13.213 6.615-13.272 20.39-9.233 32.716 1.732 5.309 6.866 9.933 11.681 12.367 6.448 3.257 14.407 3.498 20.895.321 6.785-3.318 11.621-10.215 12.795-17.715 1.154-7.52-1.293-15.463-6.268-21.174-7.224-8.305-20.04-11.422-29.87-6.515z"
            ></path>
            <path
              fill="#CCE0E5"
              d="M416.630542 60.7461213L404 84.1812412 413.004926 88 428 60.9270099 418.08867 49.5109309 425.399015 34.8187588 416.413793 31 407.310345 50.0334979z"
            ></path>
            <path
              fill="#BD8864"
              d="M375.659 71.564c1.81 9.235 7.302 17.754 15.096 22.989.512.358 1.043.676 1.574.995a26.934 26.934 0 004.586 2.17c6.2 2.149 13.128 1.452 19.21-1.016 5.136-2.07 9.702-5.274 13.875-8.937-.236.12-.492.259-.768.398-8.423 4.12-18.717 3.802-27.062-.438-6.22-3.144-12.852-9.135-15.115-16.022-4.252-12.997-5-27.229 4.113-36.703-9.624 6.25-15.883 17.496-16.159 29.06a34.437 34.437 0 00.65 7.504z"
            ></path>
            <path
              fill="#966B4E"
              d="M438.561 37.509c3.142 3.633 5.48 7.944 6.894 12.555 1.492 4.81 1.944 9.98 1.178 14.97-1.434 9.462-7.404 18.205-15.75 22.636-.236.12-.492.26-.767.4-8.405 4.131-18.677 3.812-27.004-.44-6.206-3.154-12.824-9.162-15.083-16.068-4.242-13.035-4.988-27.307 4.105-36.808 2.062-2.176 4.654-4.112 7.816-5.689 12.471-6.307 28.654-2.495 38.061 7.905.216.16.393.34.55.539zm-1.276.419c.117-.16.216-.3.334-.46-.177.08-.354.26-.334.46zm-31.09 42.796c6.364 3.233 14.22 3.473 20.622.32 6.697-3.294 11.47-10.14 12.628-17.586 1.14-7.466-1.277-15.35-6.186-21.019-7.13-8.264-19.797-11.358-29.479-6.487-13.04 6.567-13.1 20.24-9.113 32.476 1.729 5.29 6.776 9.86 11.529 12.296z"
            ></path>
            <path
              fill="#99C2D1"
              d="M469.314 10.845c-4.074 5.93-2.398 15.579 2.144 22.473 6.159 9.327 13.507 10.935 22.259 8.905 2.67-.623 5.594-3.739 6.646-6.432 1.053-2.754.663-6.151-.175-9.267-1.969-7.417-6.51-14.613-12.786-18.272-6.316-3.638-14.307-2.934-18.088 2.593z"
            ></path>
            <path
              fill="#CCE0E5"
              d="M488.146879 25.3474409L480 42.4778543 486.286414 45 496 25.2062008 488.891065 17.2362205 493.571603 6.52214567 487.28519 4 481.468788 17.8818898z"
            ></path>
            <path
              fill="#BD8864"
              d="M461.287 39.657c.02.06.08.12.101.179 1.644 2.771 3.45 5.464 5.684 7.76.528.555 1.076 1.07 1.685 1.545 5.5 4.673 12.788 6.692 19.141 5.544 4.648-.832 8.749-3.208 11.895-6.613A24.297 24.297 0 00503 43.756c-1.725 1.702-3.776 3.069-5.724 3.484-12.139 2.653-22.288.555-30.833-11.68-6.293-9.03-8.627-21.66-2.984-29.422.203-.277.406-.534.629-.811L465.367 4c-.731.416-1.32.554-3.33 2.811-2.658 3.782-4.526 8.474-5.54 13.226-1.564 7.266.71 12.77 4.79 19.62z"
            ></path>
            <path
              fill="#966B4E"
              d="M496.932 46.995c-12.11 2.627-22.236.549-30.762-11.566-6.278-8.94-8.607-21.447-2.977-29.131.203-.275.405-.53.628-.804 5.468-6.45 16.04-7.116 24.383-2.549 1.154.628 3.443 2.412 3.605 2.333 6.44 4.803 11.178 12.213 13.629 19.937.162.49.304.96.445 1.431 1.134 4.058 1.701 8.45.243 11.998-.688 1.627-1.944 3.41-3.463 4.881-1.741 1.706-3.787 3.039-5.73 3.47zm-3.483-5.607c2.774-.607 5.812-3.646 6.906-6.273 1.093-2.686.688-5.999-.183-9.037-2.045-7.234-6.764-14.252-13.284-17.82-6.562-3.548-14.845-2.882-18.794 2.529-4.232 5.783-2.49 15.193 2.228 21.917 6.4 9.096 14.034 10.664 23.127 8.684z"
            ></path>
            <path
              fill="#E6AE02"
              d="M260.132 346.329L1018 86 266.65 575.74c-14.037 9.148-32.37-2.172-30.52-18.853l23.333-209.723a1 1 0 01.669-.835z"
            ></path>
            <path
              fill="#F5D1A1"
              d="M466.274 256.647c5.317 18.274 7.595 52.499-1.013 56.133-8.297 3.514-24.229-36.123-25.261-48.157 4.713-11.994 26.274-7.976 26.274-7.976z"
            ></path>
            <path
              fill="#000"
              d="M20.509 399.929l-.26-.736a4.5 4.5 0 012.831-5.749l181.652-62.197c2.372-.816 4.964.457 5.761 2.824l.26.736a4.516 4.516 0 01-2.831 5.749L26.27 402.753c-2.353.816-4.944-.457-5.761-2.824z"
            ></path>
            <path
              fill="#000"
              d="M78.507 339.892l-.26-.746c-.818-2.401.46-5.024 2.835-5.831l86.643-30.065c2.375-.827 4.97.464 5.768 2.866l.26.746c.818 2.401-.46 5.024-2.835 5.831l-86.643 30.064c-2.355.808-4.95-.464-5.768-2.865z"
            ></path>
            <path
              fill="#000"
              d="M.25 453.21l.259.736a4.528 4.528 0 005.761 2.805l181.66-62.202a4.54 4.54 0 002.83-5.75l-.259-.735a4.555 4.555 0 00-5.761-2.825L3.08 447.442c-2.392.835-3.648 3.401-2.83 5.768z"
            ></path>
            <path
              fill="#000"
              d="M84.24 466.136l.26.767c.818 2.4 3.394 3.672 5.77 2.844l86.655-30.062c2.376-.827 3.634-3.43 2.835-5.831l-.26-.746c-.818-2.401-3.394-3.673-5.77-2.865l-86.675 30.062c-2.356.827-3.614 3.43-2.815 5.83z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Mascot
