import { useRouter } from 'next/router'
import { Box, Flex, Text } from '@traefiklabs/faency'
import Link from './Link'
import Container from './Container'
import Mascot from './Mascot'

const Footer: React.FC = () => {
  const router = useRouter()

  if (router?.route === '/terms') {
    return null
  }

  return (
    <Box as="footer">
      <Container px={3} py={4}>
        <Flex sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <Box mb={2}>
            <Mascot width={60} />
          </Box>
          <Text size={1}>
            Traefik Pilot — <Link href="/terms">Terms</Link>
          </Text>
        </Flex>
      </Container>
    </Box>
  )
}

export default Footer
