import React from 'react'

const AlertIcon: React.FC = () => {
  return (
    <svg width="34px" height="32px" viewBox="0 0 34 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g
        id="Webapp-(embed-in-Traefik)---redesigned"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        fontFamily="AppleColorEmoji, Apple Color Emoji"
        fontSize="34"
        fontWeight="normal"
      >
        <g id="My-Instances-(not-connected)" transform="translate(-425.000000, -792.000000)" fill="#000000">
          <g id="Group-4" transform="translate(406.000000, 783.000000)">
            <text id="⚠️">
              <tspan x="19" y="38">
                ⚠️
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default AlertIcon
