import React, { forwardRef } from 'react'
import { Box } from '@traefiklabs/faency'
import styled, { keyframes } from 'styled-components'

const spin = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
})

type SpinnerProps = {
  emptyColor?: string
  color?: string
  thickness?: string
  speed?: string
  label?: string
  size?: 'sm' | 'md' | 'lg'
}

const remBySize = {
  sm: 1,
  md: 2,
  lg: 3,
}

const Spinner = forwardRef<HTMLDivElement, SpinnerProps>((props, ref) => {
  const {
    thickness = '2px',
    speed = '0.45s',
    color = '#DA7E10',
    emptyColor = 'transparent',
    size = 'md',
    ...rest
  } = props

  const StyledBox = styled(Box)`
    display: inline-block;
    border-color: currentColor;
    border-style: solid;
    border-radius: 99999px;
    border-width: ${thickness};
    border-bottom-color: ${emptyColor};
    border-left-color: ${emptyColor};
    color: ${color};
    animation: ${spin} ${speed} linear infinite;
    width: ${remBySize[size]}rem;
    height: ${remBySize[size]}rem;
  `

  return (
    <StyledBox ref={ref} {...rest}>
      {/* {label && <VisuallyHidden>{label}</VisuallyHidden>} */}
    </StyledBox>
  )
})

Spinner.displayName = 'Spinner'

export default Spinner
