import { useRouter } from 'next/router'
import { useState, useRef } from 'react'
import { Avatar, Box, Button, CardLink, Flex, Menu, MenuItem } from '@traefiklabs/faency'
import styled, { createGlobalStyle } from 'styled-components'
import Container from '../Container'
import useAuth from '../../hooks/use-auth'
import isInIframe from '../../utils/is-in-iframe'
import withInnerAs from '../hoc/withInnerAs'

// HACK: Menu component is adding inline styles to the body avoiding to use onMouseEnter event correctly
// Side effect: Menu component is now stateless, DropdownMenu is not working anymore (stateful component)
const GlobalStyle = createGlobalStyle`
  body {
    pointer-events: auto !important;
    overflow: auto !important;
    padding: 0 !important;
  }
`

const CustomButton = styled(withInnerAs(Button))``

const TopNavBar: React.FC = () => {
  const { user, logout } = useAuth()
  const profileButtonRef = useRef<any | null>(null)
  const [openProfileMenu, setOpenProfileMenu] = useState<boolean>(false)
  const router = useRouter()

  return (
    <>
      <GlobalStyle />
      <Box sx={{ minHeight: 106, py: 28 }}>
        <Container>
          <Flex sx={{ alignItems: 'center', justifyContent: 'flex-end' }}>
            {user ? (
              <div
                onMouseEnter={(): void => {
                  setOpenProfileMenu(true)
                }}
                onMouseLeave={(): void => {
                  setOpenProfileMenu(false)
                }}
              >
                <CardLink
                  ref={profileButtonRef}
                  sx={{ display: 'flex', alignItems: 'center', p: 1 }}
                  onClick={() => router.push('/profile')}
                  variant="shadow"
                >
                  <Avatar src={user.picture} style={{ width: 30, height: 30 }} sx={{ mr: 1 }} />
                  {user.name}
                </CardLink>
                <Menu buttonRef={profileButtonRef} isOpen={openProfileMenu}>
                  <MenuItem
                    label="Logout"
                    onSelect={(): void => {
                      setOpenProfileMenu(false)
                      logout(isInIframe() ? { redirectUrl: document.referrer } : undefined)
                    }}
                  />
                </Menu>
              </div>
            ) : (
              <CustomButton
                variant="primary"
                innerAs="a"
                href={`/api/signup?redirect-url=${typeof window !== 'undefined' ? window?.location?.pathname : '/'}`}
              >
                Connect with Traefik Pilot
              </CustomButton>
            )}
          </Flex>
        </Container>
      </Box>
    </>
  )
}

export default TopNavBar
