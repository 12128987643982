import React, { useEffect, useState } from 'react'
import gradient from 'random-gradient'
import { Box, Flex, SkeletonBox, Text } from '@traefiklabs/faency'
import useLazyFetch from '../hooks/use-lazy-fetch'
import AdvancedSelect from './AdvancedSelect'

type OrganizationPickerProps = {
  onOrgSwitch?: () => void
}

const OrganizationPicker: React.FC<OrganizationPickerProps> = ({ onOrgSwitch = () => null }) => {
  const [selectValue, setSelectValue] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [fetchOrgs, { data: orgs, loading: isFetchingOrgs }] = useLazyFetch(
    `/api/services/organizations/external/organization`,
  )
  const [getCurrentOrgId, { data: orgData }] = useLazyFetch(`/api/current-organization`)

  const currentOrgId = orgData?.organizationId

  useEffect(() => {
    fetchOrgs()
    getCurrentOrgId()
  }, [])

  useEffect(() => {
    setSelectValue(`${currentOrgId}`)
  }, [currentOrgId])

  const handleChange = (opt) => {
    setIsLoading(true)
    setSelectValue(opt.id)
    onOrgSwitch()
    window.open(`/api/login?orgId=${opt.id}&redirect-url=/`, '_self')
  }

  if (isFetchingOrgs || orgs?.length < 2) {
    return null
  }

  return (
    <Box data-testid="organization-picker" sx={{ flex: 1 }}>
      <AdvancedSelect
        value={selectValue}
        onChange={handleChange}
        options={orgs}
        placeholder="Select Organization"
        formatOptionLabel={(opt) => (
          <Flex sx={{ alignItems: 'center' }}>
            {isLoading ? (
              <SkeletonBox mr={1} sx={{ minWidth: 24, width: 24, height: 24, borderRadius: '50%' }} />
            ) : (
              <Box
                className="advancedSelect-option-avatar"
                mr={1}
                sx={{ background: gradient(opt.id), minWidth: 24, width: 24, height: 24, borderRadius: '50%' }}
              />
            )}
            <Text
              className="advancedSelect-option-text"
              sx={{
                fontWeight: 700,
                color: 'inherit',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {opt.visibleName}
            </Text>
          </Flex>
        )}
        getOptionValue={(opt) => opt.id}
        isSearchable={false}
        noOptionsMessage="No organizations"
      />
    </Box>
  )
}

export default OrganizationPicker
