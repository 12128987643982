import React, { ReactNode, useState } from 'react'
import { Box, BoxProps, Flex, Text } from '@traefiklabs/faency'
import { createGlobalStyle } from 'styled-components'
import Head from 'next/head'
import Container from '../components/Container'
import SideBar from '../components/nav/SideBar'
import TopNavBar from '../components/nav/TopNavBar'
import Footer from '../components/Footer'
import Announcement from '../components/Announcement'
import Spinner from '../components/Spinner'
import theme from '../theme'

const BodyGlobalStyle = createGlobalStyle`
  body {
    background-color: ${theme.colors.bg};
  }

  html, body, #__next {
    height: 100vh;
    padding: 0 !important; // it was being added to the body by the Menu component
  }
`

export type Props = {
  title?: string
  children?: ReactNode
  noGutter?: boolean
} & BoxProps

const SideNavbarLayout: React.FC<Props> = ({ children, title, noGutter = false, ...rest }) => {
  const [isSwitchingOrg, setSwitchingOrg] = useState<boolean>(false)

  return (
    <>
      <BodyGlobalStyle />
      <Head>
        <title>{title ? title : 'Traefik Pilot'}</title>
      </Head>
      <Flex>
        <SideBar onOrgSwitch={() => setSwitchingOrg(true)} />
        <Flex sx={{ flex: 1, flexDirection: 'column', height: '100vh', overflow: 'auto' }}>
          {/* <Announcement /> */}
          <Box pb={noGutter ? 0 : 2} px={noGutter ? 0 : [1, 3]} {...rest} sx={{ flex: 1 }}>
            <TopNavBar />
            <Container noGutter={noGutter} sx={isSwitchingOrg ? {} : { height: '100%' }}>
              {isSwitchingOrg ? (
                <Flex sx={{ flexDirection: 'column', alignItems: 'center' }} mt={200}>
                  <Spinner />
                  <Text size={3} sx={{ mt: 3 }}>
                    Loading your organization...
                  </Text>
                </Flex>
              ) : (
                children
              )}
            </Container>
          </Box>
          <Footer />
        </Flex>
      </Flex>
    </>
  )
}

export default SideNavbarLayout
