export const pTimeout = (ms: number, promise: Promise<unknown>): any => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error('Your internet connection seems slow. Please retry later or contact the support.'))
    }, ms)
    promise.then(resolve, reject)
  })
}

export const delay = (ms: number): any => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}
