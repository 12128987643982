import React, { useState } from 'react'
import { AsyncPaginate as AsyncSelect, ComponentProps } from 'react-select-async-paginate'
import Select from 'react-select'
import { Box, theme } from '@traefiklabs/faency'

type AdvancedSelectProps = ComponentProps & {
  name?: string
  value?: string
  placeholder?: string
  options?: any[]
  loadOptions?: (changeProps: any) => Promise<any>
  formatOptionLabel?: (opt: any) => JSX.Element
  getOptionValue?: (opt: any) => string
  onChange?: (res: { value: { proxyId?: string; instanceId?: string }; option: any }) => void
  isSearchable?: boolean
  noOptionsMessage?: string
}

type getInputBorderStyleProps = {
  mode: 'normal' | 'hover' | 'readOnly' | 'disabled' | 'focus'
  theme: any
  variant?: 'normal' | 'ghost' | 'shadow'
  error?: boolean
}

export const getInputBorderStyle = ({ mode, theme, variant, error }: getInputBorderStyleProps): string => {
  const colorsByMode = {
    normal: theme.colors.gray,
    hover: theme.colors.grays[5],
    readOnly: theme.colors.gray,
    disabled: theme.colors.gray,
    focus: theme.colors.primary,
  }
  const borderStyle = error ? `inset 0 0 0 1px ${theme.colors.reds[5]}` : `inset 0 0 0 1px ${colorsByMode[mode]}`
  const shadowStyle = variant === 'shadow' ? `, 0 2px 4px 0 rgba(0, 0, 0, 0.05)` : ''

  return `${borderStyle}${shadowStyle}`
}

export const customStyles = {
  container: (provided) => ({
    ...provided,
    flex: 1,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '3px 12px',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 1,
    boxShadow: `${theme.colors.blacks[3]} 0px 2px 4px 0px`,
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
  }),
  option: (provided, state) => {
    const { isSelected } = state
    return {
      ...provided,
      position: 'relative',
      ...(isSelected
        ? {
            background: theme.colors.blues[2],
            color: theme.colors.blue,
            fontWeight: 'bold',
          }
        : {}),
      '&:hover, &:active': { background: theme.colors.blue, color: 'white' },
    }
  },
  control: (provided, state) => {
    const { isFocused } = state
    return {
      ...provided,
      minHeight: 40,
      borderWidth: 0,
      boxShadow: getInputBorderStyle({ mode: 'normal', theme, error: false, variant: 'shadow' }),
      ...(isFocused
        ? {
            boxShadow: getInputBorderStyle({ mode: 'focus', theme, error: false, variant: 'shadow' }),
            transition: 'all 0.36s cubic-bezier(0.4, 0, 0.2, 1)',
            '&:hover': { boxShadow: getInputBorderStyle({ mode: 'hover', theme, error: false, variant: 'shadow' }) },
          }
        : {}),
    }
  },
}

const AdvancedSelect: React.FC<AdvancedSelectProps> = ({
  name,
  value,
  placeholder = 'Select...',
  formatOptionLabel = () => null,
  getOptionValue = () => null,
  options: optionsProp,
  loadOptions: loadOptionsProp,
  onChange = () => null,
  isSearchable = true,
  noOptionsMessage = 'No options...',
  ...props
}) => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const [options, setOptions] = useState<any[]>([])

  const loadOptions = async (changeProps) => {
    setLoading(true)
    const res = await loadOptionsProp(changeProps)
    setOptions(res.options)
    setLoading(false)

    return res
  }
  const usedValue = (optionsProp ? optionsProp : options).find((o) => getOptionValue(o) === value)

  const SelectComponent = optionsProp ? Select : AsyncSelect

  return (
    <Box data-testid={`advanced-select-${name}`}>
      <SelectComponent
        value={usedValue}
        isLoading={isLoading}
        onChange={onChange}
        {...(optionsProp
          ? { options: optionsProp }
          : { loadOptions: loadOptions, defaultOptions: true, cacheOptions: true })}
        placeholder={placeholder}
        formatOptionLabel={formatOptionLabel}
        getOptionValue={getOptionValue}
        theme={(selectTheme) => ({
          ...selectTheme,
          borderRadius: theme.radii[1],
          colors: {
            ...selectTheme.colors,
            primary: theme.colors.blue,
            primary25: theme.colors.blues[1],
            neutral20: theme.colors.grays[3],
          },
        })}
        styles={customStyles}
        additional={{
          page: null,
        }}
        isSearchable={isSearchable}
        noOptionsMessage={() => noOptionsMessage}
        {...props}
      />
    </Box>
  )
}

export default AdvancedSelect
